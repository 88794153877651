export default function pecepVirtual() {

 const clickCard = document.querySelectorAll('.item-download')
 
 const removeSelect = (selector) => {
  clickCard.forEach(item =>{
    const itemActive = item.querySelector(selector)
    if(itemActive.hasAttribute('active'))itemActive.classList.remove('active')
  })
 }

  const toggleCard = ()=> {
    clickCard.forEach(card => { 
      const itemClicado = card.querySelector('.title-download')
      itemClicado.addEventListener('click', ()=> {
        removeSelect('.conteudo-content')
        const svg = card.querySelector('.icon-arrow-download')
        svg.classList.toggle('rotate')
        const itemActive = card.querySelector('.conteudo-content')
        itemActive.classList.toggle('active')
      })
    })
  }

toggleCard()


 const acordeonAno = document.querySelectorAll('.acordeon-ano')

 const itensTurmaAno = ()=>{
  acordeonAno.forEach(acordeonHeader=>{
    const header = acordeonHeader.querySelector('.ano-material')
    header.addEventListener('click', ()=>{
    const itenSvg = acordeonHeader.querySelector('.icon-arrow-download')
      const turmasSelecionadas = acordeonHeader.querySelectorAll('.acordeon-turma')
      itenSvg.classList.toggle('rotate')
      turmasSelecionadas.forEach(turma=>{
        turma.classList.toggle('active')
      })
    })
 })
 }

itensTurmaAno()

const itensDownload = () =>{
const acordeonTurma = document.querySelectorAll('.acordeon-turma')
 acordeonTurma.forEach(acordeonTurmaHeader=>{
  const header = acordeonTurmaHeader.querySelector('.nome-turma') 
   header.addEventListener('click', (e)=>{
     const itenSvg = header.querySelector('.icon-arrow-download')
       itenSvg.classList.toggle('rotate')
       const materialDownload = acordeonTurmaHeader.querySelectorAll('.material-donwload')
       console.log('material download', materialDownload, header);
       materialDownload.forEach(itemDonwload=>{
         itemDonwload.classList.toggle('active')
       })
    })
 })
} 

itensDownload()
  
}
