import IMask from "imask";

export default function masks() {
  document.addEventListener("DOMContentLoaded", function () {
    const telefone = document.querySelector("[js-telefone]");
    let telefoneMask;
    if (telefone) {
      telefoneMask = IMask(telefone, {
        mask: [
          {
            mask: "(00) 0000-0000",
          },
          {
            mask: "(00) 00000-0000",
          },
        ],
      });
    }

    const cpf = document.querySelector("[js-cpf]");
    let cpfMask;
    if (cpf) {
      cpfMask = IMask(cpf, {
        mask: [
          {
            mask: "000.000.000-00",
            startsWith: /[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}\-[0-9]{2}/,
          },
        ],
      });
    }

    const rg = document.querySelector("[js-rg]");
    let rgMask;
    if (rg) {
      rgMask = IMask(rg, {
        mask: [
          {
            mask: "00.000.000-00",
            startsWith: /[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}\-[0-9]{2}/,
          },
        ],
      });
    }
  });
}
