import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

export default function formAluno() {
  const showToastify = (message, opts) => {
    Toastify({
      duration: 3000,
      position: "center",
      style: {
        background: "rgba(193, 255, 129)",
        fontSize: "18px",
        color: "rgba(45, 71, 120, 1)",
      },
      text: message,
      ...opts,
    }).showToast();
  };

  const btnFormAluno = document.querySelector(".btn-submit-aluno");
  const formAluno = document.querySelector("#form-submit-aluno");

  btnFormAluno &&
    formAluno.addEventListener("submit", function (event) {
      event.preventDefault();
      btnFormAluno.disabled = true;
      btnFormAluno.style.opacity = ".5";

      const tel = formAluno.telefone.value.replace(/[\(\)\-.]/g, "");
      const rg = formAluno.rg.value.replace(/[\(\)\-.]/g, "");
      const cpf = formAluno.cpf.value.replace(/[\(\)\-.]/g, "");
      const url = "/cadastro-alunos/";
      formAluno.telefone.value = tel;
      formAluno.rg.value = rg;
      formAluno.cpf.value = cpf;

      axios
        .post(url, formAluno)
        .then((response) => showToastify("Formulário enviado com sucesso"))
        .catch((response) => showToastify("Erro ao enviar o formulário"));

      formAluno.reset();
      btnFormAluno.disabled = false;
      btnFormAluno.style.opacity = "1";
    });

  const fileImage = document.querySelector('input[type="file"]');
  const textLabel = document.querySelector(".select-file");
  fileImage.addEventListener("change", () => {
    textLabel.innerText = fileImage.value.slice(12);
  });
}
