//import axios from "axios";
//import Toastify from "toastify-js";
//import "toastify-js/src/toastify.css";

export default function formProfessor() {
  const showToastify = (message, opts) => {
    Toastify({
      duration: 3000,
      position: "center",
      style: {
        background: "rgba(193, 255, 129)",
        fontSize: "18px",
        color: "rgba(45, 71, 120, 1)",
      },
      text: message,
      ...opts,
    }).showToast();
  };

  const btnFormProfessor = document.querySelector(".btn-submit-professor");
  const formProfessor = document.querySelector("#form-submit-professor");

  btnFormProfessor &&
    formProfessor.addEventListener("submit", function (event) {
      event.preventDefault();
      //disabledBtn();
      const form_data = {
        nome: document.querySelector("#form-submit-professor input[name=nome]")
          .value,
        sobrenome: document.querySelector(
          "#form-submit-professor input[name=sobrenome]"
        ).value,
        email: document.querySelector(
          "#form-submit-professor input[name=email]"
        ).value,
        materia: document.querySelector(
          "#form-submit-professor select[name=materia]"
        ).value,
        funcao: document.querySelector(
          "#form-submit-professor select[name=funcao]"
        ).value,
        mensagem: document.querySelector(
          "#form-submit-professor textarea[name=mensagem]"
        ).value,
        curriculo: document.querySelector(
          "#form-submit-professor input[name=selecao-arquivo]"
        ).value,
      };

      console.log({ form_data });
      const url = "/fale-conosco/";
      axios
        .post(url, form_data)
        .then((response) => showMessage(response))
        .catch((response) => showMessage(response));
    });

  function showMessage(response) {
    const isSuccess = response.data && response.data.message;
    // const message = response.data ? response.data.message : response.message

    const message = isSuccess
      ? "Message sent successfully! Thank you!"
      : "Oops, something went wrong! Try again!";

    const backgroundColor = isSuccess
      ? "rgba(193, 255, 129)"
      : "rgba(233, 44, 44)";
    const textColor = isSuccess ? "rgba(45, 71, 120, 1)" : "white";

    showToastify(message, {
      style: {
        background: backgroundColor,
        color: textColor,
        fontSize: "18px",
        borderRadius: "8px",
      },
    });
    formProfessor.reset();
    //setTimeout(enabledBtn, 1000);
  }
  /*
  function disabledBtn() {
    btnFormContato.disabled = true;
    btnFormContato.classList.add("disabled");
  }

  function enabledBtn() {
    btnFormContato.disabled = false;
    btnFormContato.classList.remove("disabled");
  }
  */
}
