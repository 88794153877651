export default function pecepQuemSomos() {
    const timeLine = document.querySelectorAll('.year');
    const data = []

const activateLastCard = () => {
    timeLine?.forEach(year=>{
        const date = year
        data?.push(date)
    })

    data[data.length - 1].classList.add('year-active')
}  

const efectTimeLine = () => {
    timeLine?.forEach(year => {
        year?.addEventListener('mousemove', () => {
            timeLine.forEach(y => y.classList.remove('year-active'))
            year?.classList.add('year-active')
        })
    })
}
    
activateLastCard()
efectTimeLine()

}

/*
const efectTimeLine = () => {
        timeLine.forEach(year => {
            year.addEventListener('click', () => {
                timeLine.forEach(y => y.classList.remove('year-active'))
                year.classList.add('year-active')
            })
        })
    }
*/