export default function pecepLoginChamada() {
  const header = document.querySelector("#header");
  header.style.display = "none";

  const eyeOn = document.querySelector(".icon-eye-on");
  const eyeOff = document.querySelector(".icon-eye-off");
  const showSvg = document.querySelector(".show-svg");
  const inputValue = document.querySelector("input[name='senha']");

  const hiddenEye = () => {
    showSvg?.addEventListener("click", () => {
      if (inputValue.type === "password") inputValue.type = "text";
      else inputValue.type = "password";
      eyeOn?.classList.toggle("hidden");
      eyeOff?.classList.toggle("hidden");
    });
  };

  hiddenEye();

  const updateTitle = () =>{
    const tituloDaPagina = document.title;
    document.title =`${tituloDaPagina} Login Chamada`;
    console.log(document.title);
  }

  updateTitle()
}
