import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

export default function pecepFormChamada() {
    const formChamada = document.querySelector('#formChamada')
    const btnFormChamada = document.querySelector('#formChamada .btn')
    const materiaSelect = document.querySelector('#materia')

    const showToastify = (message, opts) => {
        Toastify({
          duration: 3000,
          position: "center",
          style: {
            background: "rgba(193, 255, 129)",
            fontSize: "18px",
            color: "rgba(45, 71, 120, 1)",
          },
          text: message,
          ...opts,
        }).showToast();
      };

    const getChecked = () => {
      const alunos = document.querySelectorAll('.item-turma')
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const turmaId = urlParams.get('turma_id');
     
      const arrPresenca = [...alunos].map((aluno, index) =>{
        return {
              aluno_id: alunos[index].dataset.id,
              presenca: alunos[index].querySelector('input:checked')?.value
          }
      })

      return {
        materia_id:materiaSelect.value,
        turma_id: turmaId,
        alunos: arrPresenca
      }
    }
  

    btnFormChamada && formChamada.addEventListener('submit', (e)=>{
        e.preventDefault()

        btnFormChamada.disabled = true;
        btnFormChamada.style.opacity = ".5";
        
        const materiaId = document.querySelector('#materia').value
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const turmaId = urlParams.get('turma_id');
       
        const data = getChecked()

        formChamada.querySelector('#id_materia').value=`${materiaId}`
        console.log('click', data);

        btnFormChamada.disabled = true
        btnFormChamada.style.opacity = '.5'
      
        axios
          .post(`/chamada/?turma_id=${turmaId}`, data)
          .then((response) =>  showToastify("Dados salvos com sucesso"))
          .catch((response) => showToastify("Erro ao enviar o formulário"));

          
        setTimeout(function() {
          btnFormChamada.disabled = false
          btnFormChamada.style.opacity = '1'
          formChamada.reset();
        }, 3500);
    })

    materiaSelect.addEventListener('change',(e)=>{
      const formData = document.querySelector('.formulario-turma')
      const paragraph = document.querySelector('#total-alunos')
      const heigthContent = document.querySelector('.container-wrapper')
      if (e.target.value === 'default'){
        formData.classList.remove('active')
        paragraph.style.display= 'none';
        heigthContent.classList.remove('heightcontent')
      }
      else {
        formData.classList.add('active')
        heigthContent.classList.add('heightcontent')
      }
    })

    const updateTitle = () =>{
      const tituloDaPagina = document.title;
      document.title =`${tituloDaPagina} Chamada`;
      console.log(document.title);
    }

    updateTitle()
  }
  