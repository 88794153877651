import mostrarSenha from "./mostrarSenha.js";
import showFormErrors from "./showFormErrors.js";
import swipersSections from "./swipers.js";
import menu from "./menu.js";
import pecepQuemSomos from "./pecep/pecepQuemSomos.js";
import pecepVirtual from "./pecep/pecepVirtual.js";
import pecepCadastroProfessores from "./pecep/pecepCadastroProfessores.js";
import accordion from "./accordion.js";
import animationNumbers from "./counter.js";
import contato from "./contato.js";
import contatoNewsletter from "./contatoNewsletter.js";
import pecepComingSoon from "./pecep/pecepComingSoon.js";
import formProfessor from "./pecep/pecep_form_professor.js";
import formAluno from "./pecep/pecep_form_alunos.js";
import pecepLoginChamada from "./pecep/pecep_login_chamada.js";
import mask from "./pecep/masks.js";
import pecepModalChamada from "./pecep/pecepModalChamada.js";
import redirectItemMenu from "./pecep/redirectItemMenu.js";
import pecepFormChamada from "./pecep/pecep_chamada.js";
import chamada from "./pecep/chamada.js";

// PAGES
const pageHome = document.querySelector(".body-home");
const pageQuemSomos = document.querySelector(".body-quem-somos");
const pageCadastroAlunos = document.querySelector(".body-selecao-alunos");
const pageCadastroProfessores = document.querySelector(
  ".body-selecao-professores"
);
const pageContribuicao = document.querySelector(".body-contribuicao");
const pageHomePecepVirtual = document.querySelector(".body-home-pecep-virtual");
const pageComingSoon = document.querySelector(".body-coming-soon");
const pageLoginChamada = document.querySelector(".body-login-chamada");
const pageModalChamada = document.querySelector(".body-modal-chamada");
const pageChamada = document.querySelector(".body-chamada");

mask();
menu();
contato();
accordion();
mostrarSenha();
showFormErrors();
animationNumbers();
contatoNewsletter();
redirectItemMenu();

// ★ Pages Scopo
if (pageHome) {
  swipersSections();
} else if (pageQuemSomos) {
  pecepQuemSomos();
  swipersSections();
} else if (pageCadastroProfessores) {
  pecepCadastroProfessores();
  formProfessor();
  swipersSections();
} else if (pageCadastroAlunos) {
  swipersSections();
  formAluno();
} else if (pageContribuicao) {
  swipersSections();
} else if (pageComingSoon) {
  pecepComingSoon();
} else if (pageLoginChamada) {
  pecepLoginChamada();
} else if (pageModalChamada) {
  pecepModalChamada();
  //pecepFormChamada()
}else if (pageChamada) {
  //pecepFormChamada()
  chamada();
}else {
  pecepVirtual();
}

document.addEventListener("DOMContentLoaded", () =>
  document.body.classList.add("dcl")
);

AOS.init({
  disable: function () {
    var maxWidth = 768;
    return window.innerWidth < maxWidth;
  },
  startEvent: "DOMContentLoaded",
  offset: 30, // offset (in px) from the original trigger point
  delay: 100, // values from 0 to 3000, with step 50ms
  duration: 1000, // values from 0 to 3000, with step 50ms
  easing: "ease", // default easing for AOS animations
  once: true, // whether animation should happen only once - while scrolling down
});
