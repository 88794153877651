export default function contato() {
  const contatoFormSubmit = document.querySelector("#form-submit");
  contatoFormSubmit &&
    contatoFormSubmit.addEventListener("submit", function (event) {
      event.preventDefault();
      disabledBtn();
      const form_data = {
        nome: document.querySelector("#nome").value,
        email: document.querySelector("#email").value,
        mensagem: document.querySelector("#mensagem").value,
      };
      console.log("Form", { form_data });
      const url = "/fale-conosco/";
      axios
        .post(url, form_data)
        .then((response) => showMessage(response))
        .catch((response) => showMessage(response));
    });

  function showMessage(response) {
    const message = response.data ? response.data.message : response.message;
    GLOBAL.showToastify(message);
    contatoFormSubmit.reset();
    setTimeout(enabledBtn, 1000);
  }

  const btnSubmit = document.querySelector("#pecep-parcerias .btn");

  function disabledBtn() {
    btnSubmit.disabled = true;
    btnSubmit.style.opacity = ".5";
  }

  function enabledBtn() {
    btnSubmit.disabled = false;
    btnSubmit.style.opacity = "1";
  }
}
