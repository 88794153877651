export default function pecepCadastroProfessores(){

const $ = document.querySelector.bind(document);
const previewImg = $('.imagem');
const fileChooser = $('.input-file');

// fileChooser.onchange = e => {
//     const fileToUpload = e.target.files.item(0);
//     const reader = new FileReader();
//     reader.onload = e => previewImg.src = e.target.result;
//     reader.readAsDataURL(fileToUpload);
// };

}
