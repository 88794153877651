export default function swipersSections() {
  ///SWIPER HOME PECEP

  const swiperIntroHome = new Swiper(".swiper-intro-home", {
    slidesPerView: 1,
    spaceBetween: 0,

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },

    loop: true,
    speed: 4000,
    autoplay: {
      // delay: 0,
      disableOnInteraction: false,
    },
  });

  const swiperColors = {
    mobile1: {
      normal: "#7BBDD7",
      active: "#5B57A6",
    },
    mobile2: {
      normal: "#FFF",
      active: "#5B57A6",
    },
    mobile3: {
      normal: "#0E0F0F",
      active: "#5B57A6",
    },
    mobile4: {
      normal: "#7BBDD7",
      active: "#5B57A6",
    },

    desktop1: {
      normal: "#7BBDD7",
      active: "#5B57A6",
    },
    desktop2: {
      normal: "#FFF",
      active: "#5B57A6",
    },
    desktop3: {
      normal: "#FFF",
      active: "#7BBDD7",
    },
    desktop4: {
      normal: "#7BBDD7",
      active: "#5B57A6",
    },
  };

  const swiperBullet = document.querySelectorAll(".swiper-pagination-bullet");
  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  swiperIntroHome.on("slideChange", function () {
    const swiperBulletActive = document.querySelector(
      ".swiper-pagination-bullet-active"
    );
    //console.log(swiperBulletActive);
    const selector = isMobile
      ? `mobile${swiperIntroHome.activeIndex}`
      : `desktop${swiperIntroHome.activeIndex}`;
    swiperBullet.forEach((bullet) => {
      bullet.style.backgroundColor = swiperColors[selector].normal;
    });
    swiperBulletActive.style.backgroundColor = swiperColors[selector].active;
  });

  ///SWIPER HOME PECEP

  /*
  const swiperQuemSomos = new Swiper(".swiper-quem-somos", {
   slidesPerView: 1,
   spaceBetween: 16,
   
   navigation: {
     nextEl: ".swiper-button-next",
     prevEl: ".swiper-button-prev",
   },
  
   breakpoints: {
     600:{
       slidesPerView: 2,
       spaceBetween: 16,
     },

     768: {
       slidesPerView: 2.8,
       spaceBetween: 16,
     },
   }
 });


  ///SWIPER HOME PECEP  

  const swiperParceiros = new Swiper(".swiper-parceiros", {
    slidesPerView: 1.1,
    spaceBetween: 16,

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    breakpoints: {

      768: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
    }
  });

  ///SWIPER DESTAQUES PECEP  

  const swiperDestaque = new Swiper(".swiper-destaque", {
    slidesPerView: 'auto',
    spaceBetween: 16,

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });


  ///SWIPER TESTE  

  var swiper = new Swiper(".mySwiper", {
    loop: true,
    spaceBetween: 10,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesProgress: true,
  });


  var swiperHistoryTop = new Swiper(".swiper-history-top", {
    loop: true,
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    thumbs: {
      swiper: swiper,
    },
  });
*/

  const breakpoint = window.matchMedia("(min-width:1024px)");

  const listSwiper = [];

  const breakpointChecker = function () {
    if (breakpoint.matches === true) {
      listSwiper?.forEach((swiper) => {
        if (swiper !== undefined) swiper.destroy(true, true);
      });
      return;
    } else if (breakpoint.matches === false) {
      return enableSwiper();
    }
  };

  const enableSwiper = () => {
    const swiperCardsQuemSomos = new Swiper(".swiper-cards-quem-somos", {
      slidesPerView: 1.2,

      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      breakpoints: {
        600: {
          slidesPerView: 2,
        },

        768: {
          slidesPerView: 2.5,
          spaceBetween: 16,
        },
      },
    });

    const swiperQuemSomos = new Swiper(".swiper-quem-somos", {
      slidesPerView: 1.5,
      spaceBetween: 8,

      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      breakpoints: {
        600: {
          slidesPerView: 2,
          spaceBetween: 16,
        },

        768: {
          slidesPerView: 3,
          // spaceBetween: 16,
        },
      },
    });

    const swiperTimeLine = new Swiper(".swiper-time-line", {
      slidesPerView: 1.1,

      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      breakpoints: {
        500: {
          slidesPerView: 1.3,
        },

        600: {
          slidesPerView: 1.7,
        },

        768: {
          slidesPerView: 2,
        },
      },
    });

    const swiperParceiros = new Swiper(".swiper-parceiros", {
      slidesPerView: 1,
      spaceBetween: 16,
      loopAdditionalSlides: 0,

      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 16,
        },
      },
    });

    const swiperDestaque = new Swiper(".swiper-destaque", {
      slidesPerView: 1,
      //spaceBetween: 16,

      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
      },

      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    const swiperHistoryBottom = new Swiper(".swiper-history-bottom", {
      loop: true,
      spaceBetween: 2,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });

    const swiperHistoryTop = new Swiper(".swiper-history-top", {
      loop: true,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiperHistoryBottom,
      },
    });

    const swiperTimeBottom = new Swiper(".swiper-time-bottom", {
      loop: true,
      spaceBetween: 2,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });

    const swiperTimeTop = new Swiper(".swiper-time-top", {
      loop: true,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiperTimeBottom,
      },
    });

    const swiperMidiaSociais = new Swiper(".swiper-midia-sociais", {
      slidesPerView: 1,

      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      breakpoints: {
        500: {
          slidesPerView: 1.3,
        },

        600: {
          slidesPerView: 1.5,
        },

        768: {
          slidesPerView: 2,
        },
      },
    });

    listSwiper.push(
      swiperCardsQuemSomos,
      swiperQuemSomos,
      swiperTimeLine,
      swiperParceiros,
      swiperDestaque,
      swiperHistoryTop,
      swiperHistoryBottom,
      swiperTimeBottom,
      swiperTimeTop,
      swiperMidiaSociais
    );
  };

  breakpoint.addListener(breakpointChecker);
  breakpointChecker();
  console.log({ listSwiper });
}
