export default function redirectItemMenu() {
  const itensMenu = document.querySelectorAll(".item-menu li > a");
  const headerEl = document.getElementById("header");

  function toggleMenu(event) {
    if (event.type === "touchstart") event.preventDefault();
    const nav = document.getElementById("nav");

    nav.classList.toggle("active");
    const active = nav.classList.contains("active");
    headerEl.classList.toggle("active");
    event.currentTarget.setAttribute("aria-expanded", active);

    if (active) {
      event.currentTarget.setAttribute("aria-label", "Fechar menu");
    } else {
      event.currentTarget.setAttribute("aria-label", "Abrir menu");
    }
  }

  itensMenu.forEach((item) => {
    if (
      item.innerText === "Seja parceiro/voluntário" ||
      item.innerText === "Contato"
    )
      item.addEventListener("click", toggleMenu);
    else console.log("Diferente");
  });
}
