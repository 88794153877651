import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

export default function pecepFormChamada() {
    const formChamada = document.querySelector('#formChamada')
    const btnFormChamada = document.querySelector('#formChamada .btn')
    const materiaSelect = document.querySelector('#materia')

    const showToastify = (message, opts) => {
        Toastify({
          duration: 3000,
          position: "center",
          style: {
            background: "rgba(193, 255, 129)",
            fontSize: "18px",
            color: "rgba(45, 71, 120, 1)",
          },
          text: message,
          ...opts,
        }).showToast();
      };

      /*
    const getChecked = () => {
      const alunos = document.querySelectorAll('.item-turma')
      const dataChamada = new Date();
      console.log('alunos tem ', alunos, dataChamada.getDate())
      const arrPresenca = [...alunos].map((aluno, index) =>{
          return {
            turma_id: materiaSelect.value,
            materia_id:materiaSelect.value,
            data_chamada:`${dataChamada.getDay()}/${dataChamada.getMonth()}/${dataChamada.getFullYear()}`,
            alunos: [{
              aluno_id: alunos[index].dataset.id,
              presenca: alunos[index].querySelector('input:checked').value
            }]
          }
      })
          return arrPresenca
    }
    */

    const getUrlSearch = () =>{
      const params = new URLSearchParams(window.location.search);
      const fieldValue = params.get('turma_id');
      return fieldValue
    }

    const getChecked = () => {
      const alunos = document.querySelectorAll('.item-turma')
      const disciplina = document.querySelector('[data-disciplina]')
      const turma = document.querySelector('[data-turma]')
      const professor = document.querySelector('[data-professor]')
      //const dataChamada = new Date();
      //console.log('alunos tem ', disciplina.dataset.disciplina)
      
      const arrPresenca = [...alunos].map((aluno, index) =>{
        return {
              aluno_id: alunos[index].dataset.id,
              presenca: alunos[index].querySelector('input:checked')?.value
          }
      })

      return {
        id:materiaSelect.value,
        turma_id: turma.dataset.turma,
        materia_id: disciplina.dataset.disciplina,
        professor_id: professor.dataset.professor,
        //data_chamada:`${dataChamada.getDay()}/${dataChamada.getMonth()}/${dataChamada.getFullYear()}`,
        alunos: arrPresenca
      }
    }

    btnFormChamada && formChamada.addEventListener('submit', (e)=>{
        e.preventDefault()
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const turmaId = urlParams.get('turma_id');

        const data = getChecked()

        console.log('Alunos', data);

        const cleanInputAlunosSend = () =>{
          const alunos = document.querySelectorAll('.item-turma')
          alunos.forEach(aluno =>{
            const radio = aluno.querySelectorAll('input[type="radio"]')
            radio.forEach(item =>{
              item.checked = false
            })
          })
        }
   
        axios
          .post(`/chamada/?turma_id=${turmaId}`, data)
          .then((response) => {
            btnFormChamada.disabled = true
            btnFormChamada.style.opacity = '.5'
            showToastify("Dados salvos com sucesso")

            setTimeout(function() {
              btnFormChamada.disabled = false
              btnFormChamada.style.opacity = '1'
              cleanInputAlunosSend()
            }, 3500);
            
          })
          .catch((response) => showToastify("Erro ao salvar a chamada"));
          
    })

    function renderAlunos(data){

      const content = document.querySelector('.conteudo-dinamico')
      const idMateria = document.querySelector('#id_materia')
      const totalAlunos = document.querySelector('#total-alunos')

      idMateria.value=  data.disciplina

      const alunos = data.alunos
      if(alunos?.length == 1){
        totalAlunos.innerHTML = `<strong>${alunos.length}</strong> aluno cadastrado`
      }else {
        totalAlunos.innerHTML = `<strong>${alunos?.length}</strong> alunos cadastrados`
      }

      const htmlAlunos = alunos?.map((aluno, index) => {
      
        return `
            <li class="item-turma"  data-id="${aluno.id}" data-disciplina="${data.disciplina}" data-turma="${data.turma}" data-professor="${data.professor}" data-id_api="${data.id}">
              <p>${aluno.nome}</p>
              <fieldset class="select-input-radio">
                  <div class="chamada">
                      <input type="radio" name="presenca_${aluno.id}" value="presente" id="presente_${aluno.id}">
                      <label for="presente_${aluno.id}">Presente</label>
                  </div>

                  <div class="chamada">
                      <input type="radio" name="presenca_${aluno.id}" value="ausente" id="ausente_${aluno.id}">
                      <label for="ausente_${aluno.id}">Ausente</label>
                  </div>

                  <div class="chamada">
                      <input type="radio" name="presenca_${aluno.id}" value="atrasado" id="atrasado_${aluno.id}">
                      <label for="atrasado_${aluno.id}">Atrasado</label>
                  </div>
              </fieldset>
        </li>
        `

      }).join('')

      content.innerHTML = htmlAlunos
    }

    function fetchAlunos(idMateria){
      // colocar aqui  idMateria 
        axios.get(`/api/disciplina/${idMateria}`)
        .then(function (response) {
            if(response.data){
              renderAlunos(response.data)
              console.log('Data Api', response.data);
            }
          })
        .catch(function (error) {
            console.log(error);
          });
    }

    materiaSelect.addEventListener('change',(e)=>{
      const formData = document.querySelector('.formulario-turma')
      const paragraph = document.querySelector('#total-alunos')
      const heigthContent = document.querySelector('.container-wrapper')
      if (e.target.value === 'default'){
        formData.classList.remove('active')
        paragraph.style.display= 'none';
        heigthContent.classList.remove('heightcontent')
      }
      else {
        formData.classList.add('active')
        heigthContent.classList.add('heightcontent')
        fetchAlunos(e.target.value)
      }
    })

    const updateTitle = () =>{
      const tituloDaPagina = document.title;
      document.title =`${tituloDaPagina} Chamada`;
      console.log(document.title);
    }

    updateTitle()
  }
  